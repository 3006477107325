<template>
  <!-- 短信模板详情 -->
  <div class="merchantConfiguration-page">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="info">
      <div class="add-btn">
        <el-button
          v-if="listFind('添加')"
          type="primary"
          size="small"
          @click="openDialogFun('新增')"
          >添加</el-button
        >
      </div>
      <div class="table-box">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          operation
          col-class-type
          operation-width="200"
        >
          <template slot-scope="scope">
            <div>
              <template>
                <el-button
                  v-if="listFind('详情查看')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="openDialogFun('查看', scope.scopeRow)"
                  >查看</el-button
                >
                <el-button
                  v-if="listFind('详情编辑')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="openDialogFun('编辑', scope.scopeRow)"
                  >编辑</el-button
                >
                <el-button
                  v-if="listFind('详情删除')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="onDelete(scope.scopeRow)"
                  >删除</el-button
                >
              </template>
            </div>
          </template>
        </Table>
      </div>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务名称：" prop="smsBusinessCode">
            <el-select
              v-model="smsBusinessCode"
              :disabled="dialogTitle === '编辑' || dialogTitle === '查看'"
              value-key="smsBusinessCode"
              clearable
              placeholder="请选择业务代码"
              @change="changeSelect"
            >
              <el-option
                v-for="(item, index) in smsBusinessCodeList"
                :key="index"
                :label="item.smsBusinessName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板CODE：" prop="smsModelCode">
            <el-input
              v-model.trim="ruleForm.smsModelCode"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入模板CODE"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="短信模板：" prop="smsModelContent">
            <el-input
              v-model.trim="ruleForm.smsModelContent"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入短信模板"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="模板说明：" prop="smsModelExplain">
            <el-input
              v-model.trim="ruleForm.smsModelExplain"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入模板说明"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  querySmsBusinessCodePage,
  querySmsModelPage, //列表
  deleteSmsModelById, //删除
  updateSmsModel, //更新
  saveSmsModel, //添加
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      smsBusinessCode: {},
      channelNo: "",
      ruleForm: {
        smsBusinessName: "",
        smsBusinessCode: "",
        smsModelCode: "",
      },
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      rules: {
        smsBusinessCode: [
          { required: true, message: "请选择业务代码", trigger: "change" },
        ],
        smsModelCode: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
      },
      smsBusinessCodeList: [], //业务代码数据
      merchantTypeList: [
        { label: "普通商户", value: 0 },
        { label: "特约商户", value: 1 },
      ], //商户类型
      tableData: [],
      titleName: [
        {
          title: "业务代码",
          props: "smsBusinessCode",
        },
        {
          title: "模板CODE",
          props: "smsModelCode",
        },
        {
          title: "模板名称",
          props: "smsBusinessName",
        },
        {
          title: "短信模板",
          props: "smsModelContent",
        },
        {
          title: "模板说明",
          props: "smsModelExplain",
        },
      ],
      total: 0,
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        this.smsBusinessCode = {};
        this.smsBusinessCodeList = [];
      }
    },
  },
  created() {},
  mounted() {
    let channelNo = this.$route.query.channelNo;
    if (channelNo) {
      this.channelNo = channelNo;
    }
    this.getTableData();
  },
  methods: {
    // 返回列表
    returnList() {
      this.$router.push("/projectTravel/SMSTemplateConfiguration");
    },
    /**
     * @description 请求列表数据
     */
    getTableData() {
      const data = {
        ...this.form,
        channelNo: this.channelNo,
      };
      querySmsModelPage(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data.total;
        }
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.getTableData();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.getTableData();
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (title === "删除") {
        return;
      } else if (title === "新增") {
      } else {
        this.smsBusinessCode = {
          smsBusinessCode: row.smsBusinessCode,
          smsBusinessName: row.smsBusinessName,
        };
        this.ruleForm = JSON.parse(JSON.stringify(row));
      }
      this.getQueryBusinessCodeList();
      this.dialogVisible = true;
    },
    /**
     * @description 删除确认框
     */
    onDelete(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteSmsModelById({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.getTableData();
            }
          });
        })
        .catch(() => {});
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      if (this.dialogTitle === "查看") {
        this.dialogVisible = false;
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = { ...this.ruleForm, channelNo: this.channelNo };
          if (this.dialogTitle === "新增") {
            saveSmsModel(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          } else if (this.dialogTitle === "编辑") {
            updateSmsModel(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          }
        }
      });
    },
    /**
     * @description 获取业务代码数据
     */
    getQueryBusinessCodeList() {
      querySmsBusinessCodePage({
        channelNo: this.channelNo,
        pageSize: 999,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.smsBusinessCodeList = res.data?.list || [];
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.smsBusinessName = val.smsBusinessName;
      this.ruleForm.smsBusinessCode = val.smsBusinessCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.merchantConfiguration-page {
  height: calc(100% - 52px);
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
  .table-box {
    margin-top: 20px;
  }
  .info {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-color: #fff;
    margin-top: 10px;
    box-sizing: border-box;
    // overflow: auto;
    // box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
